import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../../components/Shared'
import IntegrationsTemplate from '../../components/Integrations'
import helmetProps from '../../components/Integrations/SketchUp/helmetProps'
import { sanitizedCopy, sanitizedExampleFeatureData } from './utils'

export const query = graphql`
  {
    irisLogo: file(
      relativeDirectory: { eq: "integrations" }
      name: { eq: "1_IrisLogo" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 55) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    sketchUpIcon: file(
      relativeDirectory: { eq: "integrations/SketchUp" }
      name: { eq: "1_SketchUpLogo" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 55) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    sketchUpExportModel: file(
      relativeDirectory: { eq: "integrations/SketchUp" }
      name: { eq: "1.1" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 898, maxWidth: 1032, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sketchUpVRIdea: file(
      relativeDirectory: { eq: "integrations/SketchUp" }
      name: { eq: "2.1" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 768, maxWidth: 920, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sketchUpScenes: file(
      relativeDirectory: { eq: "integrations/SketchUp" }
      name: { eq: "3.2" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 560, maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sketchUpMaterials: file(
      relativeDirectory: { eq: "integrations/SketchUp" }
      name: { eq: "3.1" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 560, maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sketchUpGeolocation: file(
      relativeDirectory: { eq: "integrations/SketchUp" }
      name: { eq: "3.3" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 560, maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sketchUpLayers: file(
      relativeDirectory: { eq: "integrations/SketchUp" }
      name: { eq: "3.4" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 560, maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sketchUpComponents: file(
      relativeDirectory: { eq: "integrations/SketchUp" }
      name: { eq: "3.5" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 560, maxWidth: 960, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default memo(props => {
  const data = props.data
  const images = {
    logos: {
      iris: data.irisLogo,
      integrationCompanyIcon: data.sketchUpIcon,
    },
    ideaMockups: {
      exportModel: data.sketchUpExportModel,
      vrIdea: data.sketchUpVRIdea,
    },
  }

  const header = `Communicate design intent with speed and accuracy`
  const subHeader = `The Prospect Integration for SketchUp`
  const integrationExplanationHeader = `Go beyond 2D`
  const integrationExplanation = `Prospect makes it easy to host meetings and design reviews that communicate design intent in a way that everyone understands. Streamline review sessions with Prospect and make sure they all result in actionable feedback.`
  const sectionLinksHeader = `Prospect imports SketchUp files into VR, with:`
  const copy = {
    header,
    subHeader,
    integrationExplanationHeader,
    integrationExplanation,
    sectionLinksHeader,
  }

  const exampleFeatureData = {
    materials: {
      image: data.sketchUpMaterials,
      title: 'Materials',
      description: `All SketchUp Textures are supported in VR. Regardless of the SketchUp scene style, Prospect loads the fully textured model, ensuring your VR experiences look and feel true to the design.`,
    },
    scenes: {
      image: data.sketchUpScenes,
      title: 'Scenes',
      description: `Camera views from SketchUp Scenes are automatically converted into Viewpoints. With Viewpoints, jump between locations in a model to create a narrative as you walk a colleague or client through your project.`,
    },
    geolocation: {
      image: data.sketchUpGeolocation,
      title: 'Geolocation and sun studies',
      description: `Prospect works with SketchUp geolocation data to make your VR experience more immersive. It instantly assesses light and sun position, empowering dynamic sun and shadow studies.`,
    },
    layers: {
      image: data.sketchUpLayers,
      title: 'Layers',
      description: `SketchUp Layers are retained in VR. Showcase different design options or material combinations in one click.`,
    },
    plugin: {
      image: data.sketchUpComponents,
      title: 'Components and groups',
      description: `Prospect works with components instancing from SketchUp files, so repeating geometry appears in VR without a hitch. It also replicates Face Me components to faithfully translate 2D "billboard" elements—like PNG images—into VR.`,
    },
  }

  return (
    <>
      <Helmet {...helmetProps} />
      <IntegrationsTemplate
        images={images}
        copy={sanitizedCopy(copy)}
        exampleFeatureData={sanitizedExampleFeatureData(exampleFeatureData)}
      />
    </>
  )
})
